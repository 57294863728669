const langIt = {
  cc_components_cc_home_multiselect_placeholder_all_attributes:
    "Tutte le tipologie",
  cc_components_cc_home_multiselect_placeholder_selected_attribute:
    "{0} tipologia selezionata",
  cc_components_cc_home_multiselect_placeholder_selected_attributes:
    "{0} tipologie selezionate",
  cc_components_cc_home_multiselect_product_tags_placeholder:
      "Nessun tag selezionato",
  cc_components_cc_home_multiselect_product_tags_selection:
      "{0} tag selezionato",
  cc_components_cc_home_multiselect_product_tags_selections:
      "{0} tag selezionati",
  cc_components_cc_home_filters_categories: "Cerca per categoria",
  cc_components_cc_home_filters_types: "Cerca per tipologia",
  cc_components_cc_home_multiselect_placeholder_all_brands: "Tutti i brand",
  cc_components_cc_home_multiselect_placeholder_selected_brand:
    "{0} brand selezionato",
  cc_components_cc_home_multiselect_placeholder_selected_brands:
    "{0} brand selezionati",
  cc_components_cc_home_filters_brands: "Cerca per brand",
  cc_components_cc_home_filters_button: "Filtri",
  cc_components_cc_home_filters_label: "Filtri",
  cc_components_cc_home_all_categories: "Tutte le categorie",
  cc_components_cc_home_sort_option_sort_advices: "Ordina per consigliati",
  cc_components_cc_home_sort_option_sort_price: "Ordina per prezzo",
  cc_components_cc_home_sort_option_sort_description: "Ordina per descrizione",
  cc_components_cc_home_sorting_product_count: "Prodotti: {0}",
  cc_components_cc_home_next_page: "Carica altri risultati",
  cc_components_cc_home_no_articles:
    "Spiacenti, non sono presenti prodotti che soddisfano i criteri di ricerca",
  cc_components_cc_home_store_options_incipit:
    "Benvenuto <b>{0}</b>! Questa è la configurazione che abbiamo scelto per te. Puoi modificare il punto vendita, la tipologia di ritiro e gli orari in base alle tue preferenze.",
  cc_components_cc_home_all_articles: "TUTTI I PRODOTTI",

  // cc_components/cc_application_navigation
  cc_components_cc_application_navigation_menu_button_close: "Chiudi",
  cc_components_cc_application_navigation_menu_link_home: "Home",
  cc_components_cc_application_navigation_menu_link_about: "Chi siamo",
  cc_components_cc_application_navigation_menu_link_stores: "Punti vendita",
  cc_components_cc_application_navigation_menu_link_routes: "Giri di consegna",
  cc_components_cc_application_navigation_menu_link_information: "Contatti",
  cc_components_cc_application_navigation_menu_user_link_settings:
    "Cambia password",
  cc_components_cc_application_navigation_menu_user_account_management:
    "Il mio account",
  cc_components_cc_application_navigation_menu_user_link_logout: "Logout",
  cc_components_cc_application_navigation_menu_user_link_orders:
    "I tuoi ordini",
  cc_components_cc_application_navigation_menu_user_custom_address:
    "I tuoi indirizzi",
  cc_components_cc_application_customer_shipping_route_info_title:
    "Indirizzo inserito",
  cc_components_cc_application_customer_shipping_route_info_body_route:
    "L'indirizzo <b>{0}</b> è stato inserito ed è servito da un giro di consegna",
  cc_components_cc_application_customer_shipping_route_info_body_not_route:
    "L'indirizzo <b>{0}</b> è stato inserito ma non è servito da un giro di consegna",
  cc_components_cc_application_customer_shipping_route_info_confirm: "Chiudi",
  cc_components_cc_application_missing_number_title:
      "Numero civico mancante",
  cc_components_cc_application_missing_number_body:
      "Sembra che nell'indirizzo non sia stato specificato il numero civico. Premi \"Annulla\" per specificare il numero civico o \"Continua\" per salvare comunque l'indirizzo.",
  cc_components_cc_application_missing_number_confirm: "Continua",
  cc_components_cc_application_missing_number_cancel: "Annulla",

  // cc_components/cc_header
  cc_components_cc_header_search_placeholder: "Cosa stai cercando?",
  cc_components_cc_header_search_label: "Cerca",
  cc_components_cc_header_user_menu_orders_list: "I tuoi ordini",
  cc_components_cc_header_user_menu_change_password: "Cambia password",
  cc_components_cc_header_user_menu_account_management: "Il mio account",
  cc_components_cc_header_user_menu_logout: "Esci",
  cc_components_cc_header_site_menu_stores: "Punti vendita",
  cc_components_cc_header_site_menu_information: "Contatti",
  cc_components_cc_header_link_login: "Accedi",
  cc_components_cc_header_link_registration: "Registrati",
  cc_components_cc_header_link_user: "Utente",
  cc_components_cc_header_welcome: "Benvenuto",
  cc_components_cc_header_or: " o ",
  cc_components_cc_header_brand_name: "Click&Shop",
  cc_components_cc_header_custom_address: "I tuoi indirizzi",

  cc_components_cc_order_summary_order_info_title: "Riepilogo ordine",
  cc_components_cc_order_summary_order_info_type: "tipologia",

  cc_components_cc_order_summary_order_info_business_name: "Ragione sociale",
  cc_components_cc_order_summary_order_info_address: "Indirizzo di consegna",
  cc_components_cc_order_summary_order_info_address_List: "Gestisci indirizzi",
  cc_components_cc_order_summary_order_info_add_shipping_label: "Aggiungi",
  cc_components_cc_order_summary_order_info_add_shipping_tooltip:
    "Aggiungi indirizzo",

  cc_components_cc_order_summary_order_info_date: "data {type}",
  cc_components_cc_order_summary_order_info_store: "punto vendita",
  cc_components_cc_order_summary_order_info_total: "totale",
  cc_components_cc_order_summary_order_info_total_to_pay: "totale spesa",
  cc_components_cc_order_summary_order_info_iva_not_included: "(iva esclusa)",
  cc_components_cc_order_summary_order_notes_label: "Note per il punto vendita",
  cc_components_cc_order_summary_order_notes_placeholder:
    "Inserisci qui eventuali note per il punto vendita",
  cc_components_cc_order_summary_order_info_chars_left: "Caratteri: {0}",
  cc_components_cc_order_summary_order_info_send_order: "Invia",
  cc_components_cc_order_summary_order_info_continue_order: "Continua l'ordine",
  cc_components_cc_order_summary_order_info_go_to_cart: "Vai al carrello",
  cc_components_cc_order_summary_order_info_number: "numero",
  cc_components_cc_order_summary_order_info_status: "stato",
  cc_components_cc_order_summary_order_info_new_order: "Nuovo ordine",
  cc_components_cc_order_summary_order_info_clone_order: "Ripeti l'ordine",
  cc_components_cc_order_summary_order_save_order_title: "Conferma ordine",
  cc_components_cc_order_summary_order_save_order_body:
    "Sei sicuro di voler procedere con l'invio dell'ordine?",
  cc_components_cc_order_summary_order_save_order_cancel: "Annulla",
  cc_components_cc_order_summary_order_save_order_confirm: "Conferma",
  cc_components_cc_order_summary_order_save_order_error_title:
    "Condizioni di vendita",
  cc_components_cc_order_summary_order_save_order_error_body:
    "Per proseguire è necessario accettare le condizioni generali di vendita",
  cc_components_cc_order_summary_order_save_order_minimun_amount_error_title:
    "Ordine minimo",
  cc_components_cc_order_summary_order_save_order_minimun_amount_error_body:
    "Per poter richiedere la consegna della merce è necessario raggiungere l'importo minimo di {0}",
  cc_components_cc_order_summary_order_save_order_minimun_amount_collect_error_body:
    "Per ritirare la merce è necessario raggiungere l'importo minimo di {0}",
  cc_components_cc_order_summary_order_save_order_error_ok: "Ok",
  cc_components_cc_order_summary_order_save_order_error_close: "Chiudi",
  cc_components_cc_order_summary_order_save_order_button_information:
    "Contatta supporto",
  cc_components_cc_order_summary_order_info_sell_roles:
    "{checkbox} Accetto le {link}",
  cc_components_cc_order_summary_order_info_sell_roles_link:
    "condizioni generali di vendita",
  cc_components_cc_order_summary_order_empty_cart_button: "Svuota",
  cc_components_cc_order_summary_order_empty_cart_title: "Svuota carrello",
  cc_components_cc_order_summary_order_empty_cart_text:
    "Eliminare tutti i prodotti dal carrello?",
  cc_components_cc_order_summary_order_empty_cart_cancel: "Annulla",
  cc_components_cc_order_summary_order_empty_cart_confirm: "conferma",
  cc_components_cc_order_summary_order_save_order_error_no_delivery_date_title:
    "Consegna non disponibile",
  cc_components_cc_order_summary_order_save_order_error_no_collect_date_title:
    "Ritiro non disponibile",
  cc_components_cc_order_summary_order_customer_locked_error_title:
    "Cliente temporaneamente sospeso",
  cc_components_cc_order_summary_order_customer_locked_error_body:
    "L'account del cliente <b>{customer}</b> è temporaneamente sospeso, per maggiori informazioni si prega di contattare il supporto.",
  cc_components_cc_order_summary_order_info_no_delivery_address:
    "Nessun indirizzo di consegna selezionato",

  cc_components_cc_order_sale_options_label_store_address: "Indirizzo",
  cc_components_cc_order_sale_options_label_store_email: "E-mail",
  cc_components_cc_order_sale_options_label_store_phone: "Telefono",
  cc_components_cc_order_sale_options_label_store_fax: "Fax",
  cc_components_cc_order_sale_options_label_delivery_type: "Tipologia",
  cc_components_cc_order_sale_options_label_customer: "Cliente",
  cc_components_cc_order_sale_options_label_delivery_route_address:
    "Indirizzo di consegna",
  cc_components_cc_order_sale_options_label_delivery_route_address_new:
    "Nuovo indirizzo di consegna",
  cc_components_cc_order_sale_options_label_delivery_route_address_edit:
    "Modifica indirizzo di consegna",
  cc_components_cc_order_sale_options_label_delivery_date_time:
    "Data di consegna",
  cc_components_cc_order_sale_options_label_collect_date_time:
    "Data e ora di ritiro",
  cc_components_cc_order_sale_options_panel_title: "Opzioni di consegna",
  cc_components_cc_order_sale_options_label_no_date: "Nessuna data disponibile",
  cc_components_cc_order_sale_options_label_no_time: "Seleziona ora",
  cc_components_cc_order_sale_options_label_no_delivery_title:
    "Consegna non disponibile",
  cc_components_cc_order_sale_options_label_date_selection:
      "Seleziona una data",
  cc_components_cc_order_sale_options_label_no_collect_title:
    "Ritiro non disponibile",
  cc_components_cc_order_sale_options_change_store_title:
    "Cambio punto vendita",
  cc_components_cc_order_sale_options_change_order_type_body:
    "La consegna verrà  effettuata dal punto vendita <b>{0}</b> quindi l'assortimento potrebbe subire delle variazioni. Controlla il carrello prima di inviare l'ordine!",
  cc_components_cc_order_sale_options_change_store_body:
    "Spostando l'ordine sul punto vendita <b>{0}</b> l'assortimento potrebbe subire delle variazioni. Controlla il carrello prima di inviare l'ordine!",
  cc_components_cc_order_sale_options_change_store_confirm: "Accetta",
  cc_components_cc_order_sale_options_change_store_cancel: "Annulla",
  cc_components_cc_order_sale_options_new_shipping_customer:
    "Nuovo indirizzo di consegna",
  cc_components_cc_order_sale_options_no_delivery_route_address:
    "Non esistono giri di consegna per gli attuali indirizzi",
  cc_components_cc_order_sale_options_missing_route: "Consegna non disponibile",
  cc_components_cc_order_sale_options_exist_route: "Consegna disponibile da {0}",
  cc_components_cc_order_sale_options_missing_route_selectable:
    "Selezionare un indirizzo di consegna",

  cc_components_cc_order_option_configuration_show_on_startup:
    "{checkbox} {label}",
  cc_components_cc_order_option_configuration_show_on_startup_label:
    "Mostra all'avvio",

  cc_components_cc_customer_options_panel_title: "I tuoi Clienti",
  cc_components_cc_customer_options_panel_code: "Cod. {0}",
  cc_components_cc_customer_options_panel_main_customer: "Cliente principale",
  cc_components_cc_customer_options_panel_title_code: "({0})",

  cc_components_cc_customer_selection_no_results:
    "Spiacenti, non sono presenti clienti che soddisfano i criteri di ricerca",
  cc_components_cc_customer_search_customer:
      "Cerca tra i tuoi clienti",

  cc_components_cc_order_page_order_title: "Ordine #{0} del {1}",
  cc_components_cc_order_page_current_order_title: "Il tuo Carrello",
  cc_components_cc_order_page_current_order_no_articles:
    "Non ci sono prodotti nel carrello",
  cc_components_cc_order_confirm_orders_page_link: "I tuoi ordini",

  cc_components_cc_order_list_order_list: "Storico ordini",
  cc_components_cc_order_list_form_order_search: "Cerca ordine",
  cc_components_cc_order_list_form_order_date: "Data di invio",
  cc_components_cc_order_list_form_order_date_clean: "Pulisci",
  cc_components_cc_order_list_form_order_status: "Stato ordine",
  cc_components_cc_order_list_form_order_number: "Numero ordine",
  cc_components_cc_order_list_form_button_search: "Cerca",
  cc_components_cc_order_list_form_button_home: "Vai alla home",
  cc_components_cc_order_list_no_orders: "Non hai ancora effettuato ordini",
  cc_components_cc_order_list_search_no_orders:
    "Spiacenti, non sono presenti ordini che soddisfano i criteri di ricerca",
  cc_components_cc_order_list_next_page: "Carica altri risultati",
  cc_components_cc_order_list_form_all_status: "Tutti gli stati",

  cc_components_cc_product_page_ingredients_default_text:
    "Le indicazioni su ingredienti e allergeni dei prodotti potrebbero variare per modifiche effettuate da parte dei produttori che potrebbero temporaneamente causare variazioni tra le informazioni presenti sul sito e quelle riportate sui prodotti che vengono consegnati. Vi invitiamo quindi a verificare le informazioni riportate sull'etichetta o sul pack del prodotto (ove siano previste) prima di consumarlo o di utilizzarlo.",
  cc_components_cc_product_page_related_products_title: "Prodotti simili: {0}",
  cc_components_cc_product_page_option_name: "Denominazione",
  cc_components_cc_product_page_option_features: "Caratteristiche",
  cc_components_cc_product_page_option_brand: "Brand",
  cc_components_cc_product_page_option_merketing: "Marketing prodotto",
  cc_components_cc_product_page_option_features_header: "Caratteristiche",
  cc_components_cc_product_page_option_ingredients_header: "Ingredienti",
  cc_components_cc_product_page_option_ingredients: "Ingredienti",
  cc_components_cc_product_page_option_additives: "Additivi",
  cc_components_cc_product_page_option_allergens: "Allergeni",
  cc_components_cc_product_page_option_advices: "Consigli e avvertenze",
  cc_components_cc_product_page_option_storage: "Conservazione",
  cc_components_cc_product_page_option_recycling_notes: "Note riciclo",
  cc_components_cc_product_page_option_textual_nutritions:
    "Valori nutrizionali",
  cc_components_cc_product_page_option_numeric_nutritions:
    "Valori nutrizionali calcolati",
  cc_components_cc_product_page_option_other_information: "Altre informazioni",
  cc_components_cc_product_page_informations_sales_end:
    "Offerta valida fino al: {0}",
  cc_components_cc_product_page_informations_quantity: "Quantità  {0} {1}",
  cc_components_cc_product_page_informations_averageWeight:
    "Peso medio {0} {1}",
  cc_components_cc_product_page_informations_price: "Prezzo al {0}: {1}",
  cc_components_cc_product_page_informations_box_pieces:
    "Pezzi per cartone: {0}",
  cc_components_cc_product_page_informations_about: "circa",

  cc_components_cc_cart_item_about: "circa",

  cc_components_cc_store_list_main_title: "Punti vendita",
  cc_components_cc_store_list_label_email: "E-mail",
  cc_components_cc_store_list_label_phone: "Telefono",
  cc_components_cc_store_list_label_fax: "Fax",

  cc_components_cc_footer_informations: "Informazioni",
  cc_components_cc_footer_policy: "Policy",
  cc_components_cc_footer_company_information: "Chi siamo",
  cc_components_cc_footer_store: "Punti vendita",
  cc_components_cc_footer_routes: "Giri di consegna",
  cc_components_cc_footer_contact: "Contatti",
  cc_components_cc_footer_privacy_policy: "Privacy policy",
  cc_components_cc_footer_coockie_policy: "Cookie policy",
  cc_components_cc_footer_selling_rules: "Condizione di vendita",
  cc_components_cc_footer_copyright:
    "@ Copyright 2016-{thisYear} {interlaced-link}",

  cc_components_cc_information_list_main_title: "Contatti",
  cc_components_cc_information_list_name: "Nome",
  cc_components_cc_information_list_surname: "Cognome",
  cc_components_cc_information_list_vat_number: "Codice fiscale",
  cc_components_cc_information_list_company_name: "Ragione sociale",
  cc_components_cc_information_list_phone: "Telefono",
  cc_components_cc_information_list_email: "Email",
  cc_components_cc_information_list_message_text: "Testo",
  cc_components_cc_information_list_message_text_placeholder:
    "Inserisci qui il messaggio",
  cc_components_cc_information_list_send: "Invia la richiesta",
  cc_components_cc_information_list_privacy:
    "{checkbox} Ho letto e accetto l'{link}",
  cc_components_cc_information_list_privacy_link: "informativa sulla privacy",
  cc_components_cc_information_privacy_error_title: "Informativa sulla privacy",
  cc_components_cc_information_privacy_error_body:
    "Per proseguire è necessario accettare l'informativa sulla privacy",
  cc_components_cc_information_privacy_error_ok: "Ok",
  cc_components_cc_information_error_first_name_notnull:
    "Il campo è obbligatorio",
  cc_components_cc_information_error_last_name_notnull:
    "Il campo è obbligatorio",
  cc_components_cc_information_error_company_name_notnull:
    "Il campo è obbligatorio",
  cc_components_cc_information_error_vat_number_notnull:
      "Il campo è obbligatorio",
  cc_components_cc_information_error_phone_notnull: "Il campo è obbligatorio",
  cc_components_cc_information_error_email_notnull: "Il campo è obbligatorio",
  cc_components_cc_information_error_email_email:
    "{0} non è un indirizzo e-mail valido",
  cc_components_cc_information_list_type: "Tipo richiesta",
  cc_components_cc_information_list_store: "Punto vendita",
  cc_components_cc_information_list_type_general: "Informazioni generali",
  cc_components_cc_information_list_type_registration: "Registrazione al sito",
  cc_components_cc_information_list_type_collect: "Ritiro in punto vendita",
  cc_components_cc_information_list_type_delivery:
    "Consegna presso il tuo indirizzo",
  cc_components_cc_information_list_type_other: "Altro",
  cc_components_cc_information_list_type_none: "Selezionare un valore",
  cc_components_cc_information_error_type_notnull: "Il campo è obbligatorio",
  cc_components_cc_information_list_store_none: "Selezionare un punto vendita",

  cc_components_cc_information_confirm_title: "Richiesta inviata",
  cc_components_cc_information_confirm_header:
    "La richiesta è stata inviata con successo",
  cc_components_cc_information_confirm_body:
    "La tua richiesta di informazioni è stata correttamente inviata, uno dei nostri operatori ti risponderà  a breve.",
  cc_components_cc_information_confirm_page_link: "Homepage",

  cc_components_cc_product_stock_about: "circa",
  cc_components_cc_product_stock_average_weigth: "Peso medio",

  cc_components_cc_product_stock_controller_buy: "compra",
  cc_components_cc_product_stock_controller_not_avaible: "Non disponibile",
  cc_components_cc_product_stock_controller_not_avaible_title:
    "Quantità  non disponibile",
  cc_components_cc_product_stock_controller_quantity_label: "Quantità ",
  cc_components_cc_product_stock_controller_multiple_pack_not_avaible:
    "Non disponibile pack multiplo",
  cc_components_cc_product_stock_controller_single_pack_not_avaible:
    "Non disponibile pack singolo",
  cc_components_cc_product_stock_controller_info: "info",
  cc_components_cc_product_stock_controller_alert_not_avaible_multi:
    "Sono disponibili solamente {0} {unit} del prodotto selezionato",
  cc_components_cc_product_stock_controller_alert_not_avaible_single:
    "E' disponibile solamente un {unit} del prodotto selezionato",
  cc_components_cc_product_stock_controller_alert_ok: "Ok",
  cc_components_cc_product_stock_controller_alert_limit_over_error_title:
    "Limite di acquisto superato",
  cc_components_cc_product_stock_controller_alert_limit_over_error_multi:
    "E' possibile acquistare solamente {0} {unit} del prodotto selezionato",
  cc_components_cc_product_stock_controller_alert_limit_over_error_single:
    "E' possibile acquistare solamente un {unit} del prodotto selezionato",

  cc_page_error_title: "Errore {0}",
  cc_page_error_description: "Spiacenti, si è verificato un errore {0}",

  cc_components_cc_order_confirm_title: "Ordine inviato",
  cc_components_cc_order_confirm_body: "L'ordine è stato inviato con successo",
  cc_components_cc_order_confirm_note:
    "Si ricorda che i prezzi sono al netto dell'IVA e che il pagamento potrà  essere effettuato solo tramite fattura.",

  cc_page_error_header_title: "Errore ",
  cc_page_home_header_title: "Homepage",
  cc_page_home_header_meta_description:
    "Con Click&Shop fai la spesa online in modo facile e veloce. Ordina la tua spesa da casa e ritirala quando vuoi, nel punto vendita C+C più vicino a te.",
  cc_page_current_order_header_title: "Il tuo carrello",
  cc_page_customer_shipping_header_title: "I tuoi indirizzi",
  cc_page_orders_header_title: "Storico ordini",
  cc_page_order_header_title: "Ordine",
  cc_page_confirm_header_title: "Ordine #{0} inviato",
  cc_page_store_list_header_title: "Punti vendita",
  cc_page_store_list_header_meta_description:
    "Trova il punto vendita C+C più vicino a te.",
  cc_page_information_header_title: "Contatti",
  cc_page_information_confirm_header_title: "Richiesta inviata",
  cc_page_password_change_header_title: "Cambia password",
  cc_page_password_change_confirm_header_title: "Password modificata",
  cc_page_password_expired_header_title: "Password scaduta",
  cc_page_password_forgotten_header_title: "Password dimenticata",
  cc_page_password_forgotten_confirm_header_title:
    "Conferma password dimenticata",
  cc_page_profile_update_header_title: "Modifica profilo",
  cc_page_profile_update_confirm_header_title: "Profilo modificato",
  cc_page_profile_registration_header_title: "Registrazione",
  cc_page_profile_registration_header_meta_description:
    "Registrati e fai la spesa online in modo facile e veloce.",
  cc_page_profile_registration_confirm_header_title: "Registrazione effettuata",
  cc_page_login_header_title: "Accedi",
  cc_page_login_header_meta_description:
    "Accedi e fai la spesa online in modo facile e veloce.",

  page_password_change_title: "Cambia password",
  page_password_change_old_password_title: "Password attuale",
  page_password_change_new_password_title: "Nuova password",
  page_password_change_confirm_password_title: "Conferma password",
  page_password_change_submit: "Cambia la password",
  page_password_change_error_old_password_notnull: "Il campo è obbligatorio",
  page_password_change_error_old_password_wrong: "La password è errata",
  page_password_change_error_new_password_notnull: "Il campo è obbligatorio",
  page_password_change_error_new_password_pattern:
    "La password deve evere una lunghezza minima di 8 caratteri e contenere almeno un numero e una lettera maiuscola",
  page_password_change_error_new_password_unchanged:
    "La nuova password deve essere diversa dalla password attuale",
  page_password_change_error_confirm_password_notnull:
    "Il campo è obbligatorio",
  page_password_change_error_confirm_password_wrong:
    "La password di conferma non coincide con la nuova password",

  page_password_change_confirm_title: "Password modificata",
  page_password_change_confirm_body:
    "La tua password è stata correttamente modificata.",
  page_password_change_confirm_footer: "Continua",

  page_password_forgotten_title: "Password dimenticata",
  page_password_forgotten_email: "E-mail",
  page_password_forgotten_confirm: "RECUPERA PASSWORD",
  page_password_forgotten_error_email_notnull: "Il campo è obbligatorio",
  page_password_forgotten_error_email_email:
    "{0} non è un indirizzo e-mail valido",
  page_password_forgotten_error_email_notfound:
    "L'indirizzo e-mail {0} non è associato a nessun account attivo",

  page_password_forgotten_confirm_title: "Credenziali inviate",
  page_password_forgotten_confirm_body:
    "Le tue credenziali di accesso sono state inviate al tuo indirizzo e-mail.",
  page_password_forgotten_confirm_footer: "Continua",

  page_password_expired_title: "Reimposta password",
  page_password_expired_email: "E-mail",
  page_password_expired_old_password: "Password attuale",
  page_password_expired_new_password: "Nuova password",
  page_password_expired_confirm_password: "Conferma password",
  page_password_expired_submit: "Reimposta la password",
  page_password_expired_error_form_wrongcredentials:
    "E-mail e/o password non corretti",
  page_password_expired_error_email_notnull: "Il campo è obbligatorio",
  page_password_expired_error_email_iwemail: "L'email inserita non è valida",
  page_password_expired_error_old_password_notnull: "Il campo è obbligatorio",
  page_password_expired_error_old_password_wrong: "Password errata",
  page_password_expired_error_new_password_notnull: "Il campo è obbligatorio",
  page_password_expired_error_new_password_pattern:
    "La password deve evere una lunghezza minima di 8 caratteri e contenere almeno un numero e una lettera maiuscola",
  page_password_expired_error_new_password_unchanged:
    "La nuova password deve essere diversa dalla password attuale",
  page_password_expired_error_confirm_password_notnull:
    "Il campo è obbligatorio",
  page_password_expired_error_confirm_password_wrong:
    "La password di conferma non coincide con la nuova password",
  page_password_expired_remember_me: "{checkbox} Rimani collegato",

  page_registration_title: "Registrazione",
  page_registration_first_name: "Nome",
  page_registration_last_name: "Cognome",
  page_registration_email: "E-mail",
  page_registration_customer_code: "Codice cliente",
  page_registration_customer_vat_number_or_tax_code: "P.IVA o C.F Cliente",
  page_registration_customer_vat_number: "P.IVA",
  page_registration_submit: "REGISTRATI",
  page_registration_next_step: "AVANTI",
  page_registration_customer_code_missing: "Non hai un codice cliente?",
  page_registration_customer_selection: "Seleziona il cliente",
  page_registration_error_customernotfound: "Codice cliente non trovato",
  page_registration_error_first_name_notnull: "Il campo è obbligatorio",
  page_registration_error_first_name_size:
    "Il nome non può contenere più di 50 caratteri",
  page_registration_error_last_name_notnull: "Il campo è obbligatorio",
  page_registration_error_last_name_size:
    "Il nome non può contenere più di 50 caratteri",
  page_registration_error_email_notnull: "Il campo è obbligatorio",
  page_registration_error_email_email: "{0} non è un indirizzo e-mail valido",
  page_registration_error_email_size:
    "L'indirizzo e-mail non può contenere più di 100 caratteri",
  page_registration_error_email_notavailable:
    "L'indirizzo e-mail {0} è già  associato ad un altro utente",
  page_registration_error_customer_code_notnull: "Il campo è obbligatorio",
  page_registration_error_customer_code_size:
    "Il codice cliente non può contenere più di 6 caratteri",
  page_registration_error_customer_code_pattern:
    "Il codice cliente può contenere solo caratteri numerici",
  page_registration_error_customer_vat_number_or_tax_code_notnull:
    "Il campo è obbligatorio",
  page_registration_error_customer_vat_number_or_tax_code_notvalid:
    "Il codice inserito non è valido",

  page_registration_error_customer_name_notnull: "Il campo è obbligatorio",
  page_registration_error_customer_name_size:
    "Il nome non può contenere più di 50 caratteri",
  page_registration_error_customer_store_code_notnull:
    "Il campo è obbligatorio",
  page_registration_error_customer_vat_number_bothnull:
    "Il campo è obbligatorio",
  page_registration_error_customer_vat_number_size:
    "Il campo non può contenere più di 50 caratteri",
  page_registration_error_customer_tax_code_bothnull: "Il campo è obbligatorio",
  page_registration_error_customer_tax_code_size:
    "Il campo non può contenere più di 50 caratteri",
  page_registration_error_customer_province_code_notnull:
    "Il campo è obbligatorio",
  page_registration_error_customer_city_notnull: "Il campo è obbligatorio",
  page_registration_error_customer_city_size:
    "Il campo non può contenere più di 100 caratteri",
  page_registration_error_customer_postal_code_notnull:
    "Il campo è obbligatorio",
  page_registration_error_customer_postal_code_size:
    "Il campo non può contenere più di 10 caratteri",
  page_registration_error_customer_address_notnull: "Il campo è obbligatorio",
  page_registration_error_customer_address_size:
    "Il campo non può contenere più di 100 caratteri",
  page_registration_error_customer_phone_notnull: "Il campo è obbligatorio",
  page_registration_error_customer_phone_size:
    "Il campo non può contenere più di 100 caratteri",
  page_registration_error_customer_fax_size:
    "Il campo non può contenere più di 50 caratteri",
  page_registration_error_customer_email_email:
    "Il campo inserito non è un indirizzo e-mail valido",
  page_registration_error_customer_email_size:
    "Il campo non può contenere più di 100 caratteri",

  page_registration_privacy: "{checkbox} Ho letto e accetto l'{link}",
  page_registration_privacy_link: "informativa sulla privacy",
  page_registration_privacy_error_title: "Informativa sulla privacy",
  page_registration_privacy_error_body:
    "Per proseguire è necessario accettare l'informativa sulla privacy",
  page_registration_privacy_error_ok: "Ok",
  page_registration_customer_not_found_error_title: "Cliente non trovato",
  page_registration_customer_not_found_error_body:
    "Il codice e la partita IVA inseriti non corrispondono a nessun cliente registrato: verifica le informazioni o contatta l'assistenza.",
  page_registration_customer_not_found_error_ok: "Ok",
  page_registration_customer_not_found_error_contact: "Contatta l'assistenza",
  page_registration_customer_name: "Nome/Ragione sociale",
  page_registration_customer_store_code: "Punto vendita",
  page_registration_customer_store_code_placeholder:
    "Seleziona un punto vendita",
  page_registration_customer_cf: "Codice fiscale",
  page_registration_customer_piva: "Partita IVA",
  page_registration_customer_province: "Provincia",
  page_registration_customer_province_placeholder: "Seleziona una provincia",
  page_registration_customer_city: "Città ",
  page_registration_customer_postal_code: "C.A.P.",
  page_registration_customer_address: "Indirizzo",
  page_registration_customer_phone: "Telefono",
  page_registration_customer_fax: "Fax",
  page_registration_customer_mail: "Email",
  page_registration_customer_select_new_customer: "Nuovo cliente",
  page_registration_customer_select_insert_data:
    "Inserisci il codice fiscale o la partita IVA",
  page_registration_customer_generic_error_title:
    "Impossibile completare la registrazione",
  page_registration_customer_generic_error_body:
    "Correggere i campi evidenziati in rosso e riprovare.",
  page_registration_customer_generic_error_ok: "Ok",

  page_registration_confirm_title: "Registrazione effettuata",
  page_registration_confirm_linkHome: "Continua",
  page_registration_confirm_text:
    "La registrazione è andata a buon fine, riceverai le tue credenziali di accesso via e-mail.",

  page_profile_update_title: "Modifica profilo",
  page_profile_update_first_name: "Nome",
  page_profile_update_last_name: "Cognome",
  page_profile_update_address: "Indirizzo",
  page_profile_update_birthdate: "Data di nascita",
  page_profile_update_tax_code: "Codice fiscale",
  page_profile_update_gender: "Sesso",
  page_profile_update_gender_placeholder: "Non specificato",
  page_profile_update_city: "Città ",
  page_profile_update_postal_code: "C.A.P.",
  page_profile_update_mobile: "Cellulare",
  page_profile_update_phone: "Telefono",
  page_profile_update_fax: "Fax",
  page_profile_update_province: "Provincia",
  page_profile_update_province_placeholder: "Non specificata",
  page_profile_update_province_option_empty: "Non specificata",
  page_profile_update_submit: "Salva le modifiche",
  page_profile_update_error_first_name_notnull: "Il campo è obbligatorio",
  page_profile_update_error_first_name_size:
    "Il nome non può contenere più di 50 caratteri",
  page_profile_update_error_last_name_notnull: "Il campo è obbligatorio",
  page_profile_update_error_last_name_size:
    "Il cognome non può contenere più di 50 caratteri",
  page_profile_update_error_address_size:
    "L'indirizzo non può contenere più di 100 caratteri",
  page_profile_update_error_birthdate_past:
    "La data di nascita non può essere successiva alla data odierna",
  page_profile_update_error_birthdate_format:
    "Inserire una data nel formato 'GG/MM/AAAA'",
  page_profile_update_error_tax_code_size:
    "Il codice fiscale non può contenere più di 50 caratteri",
  page_profile_update_error_tax_code_notavailable:
    "Il codice fiscale {0} è già  associato ad un altro utente",
  page_profile_update_error_city_size:
    "La città  non può contenere più di 100 caratteri",
  page_profile_update_error_postal_code_size:
    "Il CAP non può contenere più di 10 caratteri",
  page_profile_update_error_mobile_size:
    "Il numero di cellulare non può contenere più di 50 caratteri",
  page_profile_update_error_phone_size:
    "Il numero di telefono non può contenere più di 50 caratteri",
  page_profile_update_error_fax_size:
    "Il numero di fax non può contenere più di 50 caratteri",

  page_profile_update_confirm_title: "Profilo modificato",
  page_profile_update_confirm_text:
    "Il tuo profilo è stato correttamente modificato.",
  page_profile_update_confirm_link: "Continua",

  cc_page_login_title: "Accedi",
  cc_page_login_error: "Indirizzo e-mail e/o password non corretti",
  cc_page_login_email: "Indirizzo e-mail",
  cc_page_login_password: "Password",
  cc_page_login_remember_me: "{checkbox} Rimani collegato",
  cc_page_login_submit: "Accedi",
  cc_page_login_link_registration: "Registrati",
  cc_page_login_link_link_password_forgotten: "Hai dimenticato la password?",

  cc_page_customer_address_title: "I tuoi indirizzi",
  cc_page_customer_address_form_name: "Nome / Insegna",
  cc_page_customer_address_form_address: "Indirizzo",
  cc_page_customer_address_form_city: "Città ",
  cc_page_customer_address_form_cap: "C.A.P.",
  cc_page_customer_address_form_phone: "Telefono",
  cc_page_customer_address_form_mobile: "Cellulare",
  cc_page_customer_address_form_fax: "Fax",
  cc_page_customer_address_form_email: "E-mail",
  cc_page_customer_address_form_province: "Provincia",
  cc_page_customer_address_form_note: "Note",
  cc_page_customer_address_form_update: "Salva",
  cc_page_customer_address_form_close: "Chiudi",
  cc_page_customer_address_form_back: "Indietro",
  cc_page_customer_address_form_new: "Nuovo indirizzo di consegna",
  cc_page_customer_address_form_name_notnull: "Il campo è obbligatorio",
  cc_page_customer_address_form_name_size:
    "Il campo non deve superare i 50 caratteri",
  cc_page_customer_address_form_provinceCode_notnull: "Il campo è obbligatorio",
  cc_page_customer_address_form_city_notnull: "Il campo è obbligatorio",
  cc_page_customer_address_form_city_size:
    "Il campo non deve superare i 100 caratteri",
  cc_page_customer_address_form_postalCode_notnull: "Il campo è obbligatorio",
  cc_page_customer_address_form_postalCode_size:
    "Il campo non deve superare i 10 caratteri",
  cc_page_customer_address_form_address_notnull: "Il campo è obbligatorio",
  cc_page_customer_address_form_address_notvalidateaddress: "Indirizzo non valido, digita l'indirizzo e seleziona uno dei valori proposti",
  cc_page_customer_address_form_address_size:
    "Il campo non deve superare i 100 caratteri",
  cc_page_customer_address_form_phone_size:
    "Il campo non deve superare i 50 caratteri",
  cc_page_customer_address_form_mobile_size:
    "Il campo non deve superare i 50 caratteri",
  cc_page_customer_address_form_fax_size:
    "Il campo non deve superare i 50 caratteri",
  cc_page_customer_address_form_email_iwemail: "L'email inserita non è valida",
  cc_page_customer_address_form_email_size:
    "Il campo non deve superare i 100 caratteri",

  cc_page_customer_shipping_title: "Ordine #{0} del {1}",
  cc_page_customer_shipping_current_order_title: "I tuoi indirizzi",
  cc_page_customer_shipping_confirm_orders_page_link: "I tuoi ordini",

  cc_customer_shipping_delete: "Elimina",
  cc_customer_shipping_update: "Modifica",
  cc_customer_shipping_delete_title: "Elimina indirizzo di consegna",
  cc_customer_shipping_delete_body:
    "Sei sicuro di voler procedere con l'eliminazione dell'indirizzo?",
  cc_customer_shipping_delete_cancel: "Annulla",
  cc_customer_shipping_delete_confirm: "Conferma",
  cc_customer_shipping_update_shipping: "Modifica indirizzo",
  cc_customer_shipping_delete_shipping: "Elimina indirizzo",
  cc_customer_shipping_no_routes:
    "Consegna non disponibile per questo indirizzo",
  cc_customer_shipping_exist_route:
    "Consegna disponibile da <strong>{0}</strong>",
  cc_customer_shipping_selected_route:
    "Indirizzo di consegna per <strong>{0}</strong>",
  cc_customer_shipping_select_shipping: "Seleziona un componente",
  cc_customer_shipping_select_action: "Seleziona",
  cc_customer_shipping_tel: "Numero di telefono:",
  cc_customer_shipping_mobile: "Numero di cellulare:",
  cc_customer_shipping_email: "Indirizzo e-mail:",
  cc_customer_shipping_note: "Note:",

  enum_packagingtype_UNIT_label: "Singolo",
  enum_packagingtype_BOX_label: "Pacco",
  enum_packagingtype_WHOLE_label: "Intero",
  enum_packagingtype_PACKAGE_label: "Cartone",

  enum_account_status_PENDING_label: "",
  enum_account_status_ACTIVE_label: "",
  enum_account_status_LOCKED_label: "",

  enum_customer_status_ACTIVE_label: "",
  enum_customer_status_LOCKED_label: "",
  enum_customer_status_DISMISSED_label: "",

  enum_order_type_COLLECT_label: "Ritiro",
  enum_order_type_DELIVERY_label: "Consegna",
  enum_order_type_COLLECT_description: "Ritiro in punto vendita",
  enum_order_type_DELIVERY_description: "Consegna presso il tuo indirizzo",

  enum_product_attribute_BIOLOGICAL_label: "Biologico",
  enum_product_attribute_GLUTEN_FREE_label: "Senza glutine",
  enum_product_attribute_LACTOSE_FREE_label: "Senza lattosio",
  enum_product_attribute_YEAST_FREE_label: "Senza lievito",
  enum_product_attribute_PALM_OIL_FREE_label: "Senza olio di palma",
  enum_product_attribute_SUGAR_FREE_label: "Senza zuccheri",
  enum_product_attribute_HUNDRED_PERCENT_ITALIAN_label: "100% italiano",
  enum_product_attribute_VEGETABLE_label: "Vegetale",
  enum_product_attribute_WHOLEMEAL_label: "Organico",
  enum_product_attribute_SALT_FREE_label: "Senza sale",

  enum_product_category_FIRST_label: "xxx",
  enum_product_category_SECOND_label: "yyy",
  enum_product_category_THIRD_label: "zzz",

  enum_product_package_type_PACKAGE_label: "xxx",
  enum_product_package_type_BOX_label: "yyy",
  enum_product_package_type_WHOLE_label: "zzz",

  enum_role_ADMIN_label: "Amministratore",

  enum_store_status_ACTIVE_label: "Attivo",
  enum_order_status_PROCESSED_label: "Evaso",
  enum_order_status_PENDING_label: "In lavorazione",
  enum_order_status_CANCELED_label: "Cancellato",
  enum_order_status_SHIPPED_label: "Merce consegnata",
  enum_order_status_DRAFT_label: "Aperto",

  enum_gender_MALE_label: "Maschio",
  enum_gender_FEMALE_label: "Femmina",
  enum_gender_null_label: "Non specificato",

  // multiselect
  "multiselect-placeholder": "Seleziona un valore",
  "multiselect-search-placeholder":
    "Digita il nome per attivare l'autocomplete",
  "multiselect-no-options": "Non ci sono valori da selezionare",
  "multiselect-no-result": "Nessun risultato trovato",
};
export default langIt;
